import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          {/*<span className="image main"><img src={pic01} alt="" /></span>*/}
          <p> Started in Chicago by two college roommates with an idea, First Street became a cryptocurrencies & digital assets quantitative trading firm. </p>
          <p> Now grown into modern engineering team of twelve - remote and distributed across North America, First Street offers services across cryptocurrency exchanges: remarketing, quantitative trading, algorithmic development, and orderbook analysis. </p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Services</h2>
          {/*<span className="image main"><img src={pic02} alt="" /></span>*/}
          <h4> Cryptocurrencies and digital assets </h4>
          <div>
              <ul>
                  <li> Remarketing and arbitrage </li>
                  <li> Tightening orderbook spreads </li>
                  <li> Keeping orderbooks full </li>
                  <li> Automatic pool rebalancing </li>
                  <li> Quantitive trading </li>
                  <li> Orderbook analysis </li>
                  <li> Algorithmic development </li>
                  <li> Centralize exchange and DEX support </li>
              </ul>
          </div>
          <div>
              <p> Services range from 5k-35k/mo depending on volume moved, pairs traded, development cost, exchange liquidity, and other factors. </p>
          </div>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          {/*<span className="image main"><img src={pic03} alt="" /></span>*/}
          <p> We are a global liquidity provider and market maker, trading cryptocurrencies & digital assets on major exchanges. </p>
          <p> Technology is at the core of how we approach trading, and we consider ourselves as much a technology company as a trading firm. </p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <form method="post" action="https://us-central1-first-street-1540149211848.cloudfunctions.net/contact">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          {/*
          <ul className="icons">
            <li><a href="#" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a href="#" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="#" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>
          */}
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
